import React, { useEffect, useState } from "react";
import FormInput from "../components/FormInput";
import FormRowSelect from "../components/FormRowSelect";
import { useGlobalContext } from "../context/UserContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const AirtimeToCash = () => {
  const {
    networkList,
    selectedNetwork,
    handleChange,
    isLoading,
    loadingText,
    amount,
    phoneNumber,
    OTPSent,
    OTPVerified,
    otp,
    airtimeBalance,
    sendOTP,
    verifyOTP,
    transferAirtime,
    transferPin,
  } = useGlobalContext();
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    handleChange({ name, value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!OTPSent) return sendOTP();
    if (OTPSent & !OTPVerified) return verifyOTP();
    if (OTPSent & OTPVerified) return transferAirtime();
  };
  const [amountToReceive, setAmountToReceive] = useState(0);
  useEffect(() => {
    if (amount) {
      let charges = 0.8;
      let noOfTransfers = parseInt(amount / 5000);

      if (noOfTransfers < 1) {
        noOfTransfers = 1;
      }
      if (amount >= 5000) charges = 0.85;
      if (amount >= 10000) charges = 0.9;
      let calculatedAmount = amount * charges * noOfTransfers;
      // console.log(calculatedAmount);
      setAmountToReceive(calculatedAmount);
    }
  }, [amount]);
  return (
    <div className="md:ml-[6rem] bg-white p-4 ">
      <h2 className="title underline">Airtime To Cash</h2>
      <h2 className=" capitalize text-sm font-bold text-center">
        Convert all your excess airtime to cash/fund wallet
      </h2>
      <form className="form" onSubmit={handleSubmit}>
        <FormRowSelect
          list={networkList}
          labelText="select network"
          handleChange={handleInputChange}
          value={selectedNetwork}
          name="selectedNetwork"
        />
        <FormInput
          type="number"
          labelText="sender number"
          name="phoneNumber"
          value={phoneNumber}
          disabled={OTPSent}
          handleChange={handleInputChange}
        />
        {OTPSent & !OTPVerified ? (
          <FormInput
            type="number"
            labelText="Enter OTP"
            name="otp"
            value={otp}
            placeHolder="OTP"
            disabled={OTPVerified}
            handleChange={handleInputChange}
          />
        ) : (
          ""
        )}

        {/* <div className="btn btn-block">Send OTP</div> */}
        {OTPVerified && (
          <>
            <FormInput
              type="number"
              labelText="amount"
              name="amount"
              value={amount}
              placeHolder="amount"
              handleChange={handleInputChange}
            />
            <FormInput
              type="number"
              labelText="transfer Pin"
              name="transferPin"
              value={transferPin}
              placeHolder="transfer Pin"
              handleChange={handleInputChange}
            />
            <div className="flex">
              <p className="font-bol">Your Balance: {airtimeBalance}</p>
            </div>
          </>
        )}
        <small></small>
        {OTPVerified && (
          <p className="font-bold text-center">
            You will receive:₦{amountToReceive}{" "}
          </p>
        )}
        <div className="flex justify-center items-center gap-2  ">
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-block  "
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-3" />
                {loadingText}
              </>
            ) : OTPSent & !OTPVerified ? (
              "verify"
            ) : (
              "submit"
            )}
          </button>
          {OTPSent & !OTPVerified ? (
            <div
              onClick={() => sendOTP()}
              className="btn btn-hipster btn-block text-center "
            >
              resend OTP
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};
